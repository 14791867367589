import React, {useState} from 'react';
import "./footer.css";
import logo from "../../Images/walls-logo-large.png";
import { Link } from 'react-router-dom';
import loading from "../../Images/loader.svg";
import axios from 'axios';
function Footer() {
    const [newsEmail, setnewsEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const getCurrentYear = () => {
        const now = new Date();
        return now.getFullYear();
    }

    const sendNewsleterSubscribtion = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const ans  =  await axios.post("https://api.walls.co.tz/mail/subscribe", {email: newsEmail});
            console.log(ans.data);
            setIsLoading(false);
            e.target.reset();
        } catch (error) {
            setIsLoading(false);
        }
    }
    return (
        <div className="footerMain">
            <div className="footerTopSection">
                <div className="footerTopSecLeft">
                    <div className="FooterLogoSec">
                        <img src={logo} alt="logo" className="footerlogo" />
                        <div className="footerCompanyName">Walls International</div>
                    </div>
                    <div className="footerTopSectSlogan">
                        We remain committed to delivering the best results for our clients and maintaining a positive impact on the environment and the communities we serve.
                    </div>
                </div>
                <div className="footerTopSecRight">
                    <div className="footerColumn">
                        <div className="footerColumnHeader">
                            Site
                        </div>
                        <Link to={"/about"}>
                            <div className="footerColumnText">
                                About Us
                            </div>
                        </Link>
                        <Link to={"/services"}>
                        <div className="footerColumnText">
                            Our Services
                        </div>
                        </Link>
                        <div className="footerColumnText">
                           Client Area
                        </div>
                    </div>
                    <div className="footerColumn">
                        <div className="footerColumnHeader">
                            Services
                        </div>
                        <div className="footerColumnText">
                            Cargo Tallying
                        </div>
                        <div className="footerColumnText">
                           Lashing & Unlashing
                        </div>
                        <div className="footerColumnText">
                           Vehicle Discharge
                        </div>
                        <div className="footerColumnText">
                           Garbage Collection
                        </div>
                        <div className="footerColumnText">
                           Hatch Sweeping
                        </div>
                    </div>
                    <div className="footerColumn">
                        <div className="footerColumnHeader">
                            Services
                        </div>
                        <div className="footerColumnText">
                            Sludge Disposal
                        </div>
                        <div className="footerColumnText">
                            Watchmen
                        </div>
                        <div className="footerColumnText">
                           Bunkering
                        </div>
                        <div className="footerColumnText">
                           Fumigation
                        </div>
                        <div className="footerColumnText">
                           Dunnaging
                        </div>
                    </div>
                    <div className="footerColumn">
                        <div className="footerColumnHeader">
                            Services
                        </div>
                        <div className="footerColumnText">
                            Bagging
                        </div>
                        <div className="footerColumnText">
                            Stowaway Search
                        </div>
                        <div className="footerColumnText">
                           HSE
                        </div>
                        <div className="footerColumnText">
                           Weighing Supervison
                        </div>
                        <div className="footerColumnText">
                           Container Stuffing
                        </div>
                        <div className="footerColumnText">
                           Container Unstuffing
                        </div>
                    </div>
                </div>
            </div>
            <div className="newsletterSection">
                <div className="newsletterLeftSection">
                    <div className="newsLetterHeader">Join our newsletter</div>
                    <div className="newsletterPara">We'll send you a nice letter once per week. No spam.</div>
                </div>
                <div className="newsletterRightSection">
                    <form onSubmit={(e) => sendNewsleterSubscribtion(e)} >
                        <div className="emailInputAndButtonHolder">
                            <input type="email" onInput={(e) => setnewsEmail(e.currentTarget.value)} required className="newleterEmailInput" name="email" placeholder="Enter your email" />
                            <img src={loading} className="newsLeterLoading" alt="loading icon" style={!isLoading ? {display: "none"} : {}} />
                            <button type="submit" className="newsleterSubmitButton" style={isLoading ? {display: "none"} : {}}>Subscribe</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="contactAndCopyRights">
                <div className="conctactFooterSection">
                    <h3>Contact Us</h3>
                    <div className="contactTellFooter">Email: info@walls.co.tz</div>
                    <div className="contactTellFooter">Tel: +255 763 175 801, +255 786 833 930</div>
                </div>
                <div className="copyrightsRightSection">
                    <span className="material-symbols-outlined">
                        copyright
                    </span>
                    <div className="copyRightsTextInfo">
                        {
                            getCurrentYear()+" Walls International LTD"
                        }
                    </div>
                     
                </div>
            </div>
        </div>
    );
}

export default Footer;

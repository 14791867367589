import React from 'react';
import "./services.css";
import ServiceRow from './ServiceRow/ServiceRow';

function Services() {
    return (
        <div className="ServicesMain">
              <h1 className="ServiceHead">Our Services</h1>
              <div className="serviceRowHolder">
                <ServiceRow
                    head="Cargo Tallying, Inspection and Survey"
                    description="We provide advanced cargo tallying and inspection services, ensuring accuracy and compliance with international standards. From tallying RORO info to pre-shipment inspections and final destination surveys, we guarantee precise and reliable results."
                    image="https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg"
                    isLeft={true}
                />
              </div>
              <div className="serviceRowHolder">
                <ServiceRow
                    head="Lashing and Unlashing Services"
                    description="Our team of trained experts ensures that cargo is securely lashed and unlashed using high-quality equipment, adhering to strict safety protocols for smooth and efficient operations."
                    image="https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg"
                    isLeft={false}
                />
              </div>
              <div className="serviceRowHolder">
                <ServiceRow
                    head="Supervision of Vehicle Discharge (Super Cargo)"
                    description="We oversee cargo discharge processes to ensure efficient unloading according to the shipper’s instructions, preventing damage and maintaining integrity throughout the operation."
                    image="https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg"
                    isLeft={true}
                />
              </div>
              <div className="serviceRowHolder">
                <ServiceRow
                    head="Garbage Collection"
                    description="We offer comprehensive waste management services, ensuring the proper segregation and disposal of all types of waste, including hazardous and food waste."
                    image="https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg"
                    isLeft={false}
                />
              </div>
              <div className="serviceRowHolder">
                <ServiceRow
                    head="Hatch sweeping and Cleaning"
                    description="Our hatch cleaning services use advanced equipment to guarantee clean and safe cargo holds, with thorough inspections for waste residue."
                    image="https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg"
                    isLeft={true}
                />
              </div>
              <div className="serviceRowHolder">
                <ServiceRow
                    head="Sludge Disposal"
                    description="We specialize in the environmentally safe removal and disposal of oil sludge and other machinery waste products, adhering to stringent environmental regulations."
                    image="https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg"
                    isLeft={false}
                />
              </div>
              <div className="serviceRowHolder">
                <ServiceRow
                    head="Watchmen Services"
                    description="For enhanced security, our watchmen services monitor the safety and progress of cargo operations, ensuring everything runs smoothly."
                    image="https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg"
                    isLeft={true}
                />
              </div>
              <div className="serviceRowHolder">
                <ServiceRow
                    head="Bunkering"
                    description="We provide safe and efficient bunkering services, adhering to safety measures that prevent spills, contamination, and other risks."
                    image="https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg"
                    isLeft={false}
                />
              </div>
              <div className="serviceRowHolder">
                <ServiceRow
                    head="Fumigation"
                    description="We offer pest control services for cargo containers using international-standard fumigation techniques to ensure complete elimination of pests."
                    image="https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg"
                    isLeft={true}
                />
              </div>
              <div className="serviceRowHolder">
                <ServiceRow
                    head="Supply of Crew Consumables"
                    description="Our team ensures that ships’ crews are well-supplied with essentials like food, water, and toiletries to support their wellbeing throughout voyages."
                    image="https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg"
                    isLeft={false}
                />
              </div>
              <div className="serviceRowHolder">
                <ServiceRow
                    head="Crew Change (Other Crew Logistics)"
                    description="We facilitate seamless crew changes, arranging transportation, immigration clearance, and accommodation while minimizing vessel downtime."
                    image="https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg"
                    isLeft={true}
                />
              </div>
              <div className="serviceRowHolder">
                <ServiceRow
                    head="Dunnaging"
                    description="We expertly secure cargo using dunnage materials to prevent shifting during transit, ensuring stability and protection from damage."
                    image="https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg"
                    isLeft={false}
                />
              </div>
              <div className="serviceRowHolder">
                <ServiceRow
                    head="Container Stuffing and Unstuffing"
                    description="Our container handling services ensure efficient and safe stuffing and unstuffing, optimizing space and preventing damage to cargo."
                    image="https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg"
                    isLeft={true}
                />
              </div>
              <div className="serviceRowHolder">
                <ServiceRow
                    head="Weighing Supervision"
                    description="Accurate cargo weighing is crucial for vessel safety, and we oversee this process to ensure compliance with SOLAS regulations."
                    image="https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg"
                    isLeft={false}
                />
              </div>
              <div className="serviceRowHolder">
                <ServiceRow
                    head="Stowaway Search"
                    description="Our security teams perform thorough inspections to detect unauthorized individuals onboard, ensuring the safety of the crew and vessel."
                    image="https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg"
                    isLeft={true}
                />
              </div>
              <div className="serviceRowHolder">
                <ServiceRow
                    head="Bagging"
                    description="We provide bagging services for bulk cargo like grain and fertilizer, ensuring easy handling and transportation with precise packaging and spill prevention."
                    image="https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg"
                    isLeft={false}
                />
              </div>
              <div className="serviceRowHolder">
                <ServiceRow
                    head="HSE Outsourced (Health, Safety, and Environment)"
                    description="We partner with third-party specialists to manage health, safety, and environmental compliance, ensuring your operations meet all regulatory standards."
                    image="https://openismila.s3.amazonaws.com/walls-site/test-image1.jpg"
                    isLeft={true}
                />
              </div>
        </div>
    );
}

export default Services;
